<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    v-bind:class="{ primary: !isDark, 'primary darken-3': isDark }"
  >
    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="{ name: item.name }"
      >
        <v-list-item-icon>
          <v-icon class="white--text">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "navigation-drawer",

  props: ["navbar", "isDark"],

  watch: {
    navbar: function(val) {
      this.drawer = val;
    },
    drawer: function() {
      this.$emit("onNavBar", this.drawer);
    },
  },

  data: () => ({
    drawer: false,
    items: [
      { title: "Inicio", icon: "mdi-home", name: "home" },
      { title: "Mis Recetas", icon: "mdi-medical-bag", name: "recetas" },
      {
        title: "Configuración",
        icon: "mdi-account-settings",
        name: "configuracion",
      },
    ],
    mini: true,
  }),

  mounted() {},
};
</script>

<style></style>
